
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import {
  locationIcon,
  cashbackGraphic,
  cashForwardGraphic,
  boostGraphic,
  boostOfferGraphic,
  cashOfferGraphic,
  offerOverlay
} from '@/utils/image-paths';
import isMobile from '@/utils/mobile-detect';
import { getBgColor } from '@/utils/helperFunctions';
import Modal from '../modal/Modal.vue';
import OfferDetails from './OfferDetails.vue';

// Import icons
import Location from '@/components/offers/icons/Location.vue';
import Info from '@/components/offers/icons/Info.vue';
import { OfferDetail, IStore } from '@/types/offer.types';
import { IRedemption } from '@/types/redeemed-offers.types';

@Component({
  components: { Modal, OfferDetails, Location, Info }
})
export default class OfferCard extends Vue {
  @Prop() offer!: OfferDetail;
  @Prop() redemption!: IRedemption;

  @Prop() title!: string;
  @Prop() isRedeemedOffer!: boolean;
  @Prop() inprogress!: boolean;

  @Prop() comboOffer!: any;
  @Prop() offerCount!: number;

  @Ref('mainModel') modal!: any;

  location: string = locationIcon;
  boostOfferGraphicImg: string = boostOfferGraphic;
  cashOfferGraphicImg: string = cashOfferGraphic;
  offerOverlayImg: string = offerOverlay;

  cashForwardAmount = 0;
  offerBackground = '';
  matchingValuePercent = 0;

  get brandWebsite(): string {
    if (this.offer.brandWebsite) return new URL(this.offer.brandWebsite).host;
    return '';
  }

  get isOnlineOnly(): boolean {
    if (this.offer.reach) return this.offer.reach === 'online_only';
    return false;
  }

  get store(): IStore {
    if (this.offer.storeDetails && this.offer.storeDetails.length > 0) return this.offer.storeDetails[0];
    return {};
  }

  get status() {
    // Active , Expired , Scheduled,  Archived
    if (this.offer.offerState === 'Scheduled') return 'Coming Soon';
    else if (this.offer.offerState === 'active') return `limit of ${this.offer.redeemLimitPerUser}`;
    return this.offer.offerState;
  }

  get redemptionStatus() {
    // earned , in_progress , expired
    if (this.redemption.rewardStatus === 'earned') return 'Redeemed';
    if (this.redemption.rewardStatus === 'in_progress') {
      if (this.offer.redemptionTrigger === 'purchase_frequency')
        return `In progress ${this.redemption.transactions.length}/${this.offer.purchaseFrequency}`;
      else if (this.offer.redemptionTrigger === 'cumulative_purchase_amount')
        return `In progress $${this.redemption.purchaseValue.toFixed(2)}/$${this.offer.purchaseAmount}`;
    }
    return this.redemption.rewardStatus;
  }

  get rewardValue() {
    if (this.offer.reward != null) {
      const value = this.offer.rewardValue;
      const type = this.offer.rewardType;
      return type === 'fixed' ? `$${value}` : `${value}%`;
    }
    return '';
  }

  created(): void {
    this.offerBackground = getBgColor(this.title);
    if (this.offer.matchings)
      this.matchingValuePercent = this.offer.matchings
        .map((b) => b.matchingValue)
        .reduce((previous, current): number => previous + current, 0);
  }

  getOfferGraphic(): string {
    let graphic = '';

    if (this.title === 'Cash Back') {
      graphic = cashbackGraphic;
    } else if (this.title === 'Cash Forward') {
      graphic = cashForwardGraphic;
    } else if (this.title === 'Boost') {
      graphic = boostGraphic;
    }

    return graphic;
  }

  navigateToDetailsPage(): void {
    if (isMobile() === true) {
      this.$router.push(`/offer-details?id=${this.offer.id}`);
    } else {
      this.modal.openModal();
    }
  }

  closeTheModel(): void {
    this.modal.closeModal();
  }
}
