var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"offer-card supporting-card",on:{"click":function($event){return _vm.navigateToDetailsPage()}}},[_c('div',{staticClass:"card-head",class:{ 'single-offer-head': _vm.offerCount === 1 },style:({
        background: `url(${_vm.offer.coverImg})`,
        borderBottom: `4px solid ${_vm.offerBackground} !important`
      })},[_c('div',{staticClass:"offer-overlay overlay-height",style:({
          background: `url(${_vm.offerOverlayImg})`
        })},[_c('div',{staticClass:"head-content-container"},[_c('span',{staticClass:"cashback-title"},[_vm._v(_vm._s(_vm.rewardValue)+" "+_vm._s(_vm.title))]),(_vm.isOnlineOnly)?_c('div',{staticClass:"offer-online-sticker"},[_c('span',[_vm._v(" Online Shop")])]):_vm._e(),_c('div',{staticClass:"title-background"},[(_vm.offer.matchings)?_c('div',{staticClass:"combo-graphics-holder"},[_c('div',{staticClass:"amount-container",style:({
                  backgroundImage: `url(${_vm.cashOfferGraphicImg})`
                })},[_c('div',{staticClass:"offer-amount amount-combo-first"},[_vm._v(" "+_vm._s(_vm.rewardValue)+" ")])]),_c('div',{staticClass:"amount-container-second",style:({
                  backgroundImage: `url(${_vm.boostOfferGraphicImg})`
                })},[_c('div',{staticClass:"offer-amount amount-combo-second"},[_vm._v("Boost "+_vm._s(_vm.matchingValuePercent)+"%")])])]):_c('div',{staticClass:"graphics-holder",style:({
                background: `url(${_vm.getOfferGraphic()})`
              })},[_c('div',{staticClass:"offer-amount"},[_vm._v(" "+_vm._s(_vm.rewardValue)+" ")])])])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"brand-row"},[_c('span',{staticClass:"brand-name"},[_vm._v(_vm._s(_vm.offer.brand.name))]),_c('img',{staticClass:"brand-logo logo-img",attrs:{"src":_vm.offer.brand.logoUrl}})]),_c('div',{staticClass:"card-row"},[_c('span',{staticClass:"brand-address offer-desc offer-short-description"},[_vm._v(" "+_vm._s(_vm.offer.title)+" ")])]),_c('div',{staticClass:"card-row-revrse status-row-wrapper"},[(_vm.isRedeemedOffer)?_c('span',{staticClass:"status",class:_vm.status.includes('limit of') ? 'active' : _vm.status},[_vm._v(_vm._s(_vm.redemptionStatus)+" ")]):_c('span',{staticClass:"status",class:_vm.status.includes('limit of') ? 'active' : _vm.status},[_vm._v(" "+_vm._s(_vm.status)+" ")])]),_c('div',{staticClass:"brand-row address-row"},[_c('div',{staticClass:"card-row address-holder"},[(_vm.isOnlineOnly)?[_c('Info',{attrs:{"color":_vm.offerBackground}}),_c('span',{staticClass:"brand-address"},[_vm._v(_vm._s(_vm.brandWebsite))])]:[_c('Location',{attrs:{"color":_vm.offerBackground}}),_c('span',{staticClass:"brand-address"},[_vm._v(_vm._s(_vm.store.address1))])]],2),_c('span',{staticClass:"offer-date"},[_vm._v(" "+_vm._s(_vm.status.includes('limit of') ? 'Valid' : '')+" "+_vm._s(_vm.offer.startDate)+" - "+_vm._s(_vm.offer.endDate)+" ")])])])]),[_c('modal',{ref:"mainModel",scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true},{key:"body",fn:function(){return [_c('OfferDetails',{attrs:{"offer":_vm.offer,"title":_vm.title,"status":_vm.status,"address":_vm.store,"comboOffer":_vm.comboOffer,"matchingValuePercent":_vm.matchingValuePercent,"cashForwardAmount":_vm.cashForwardAmount},on:{"closeTheModel":_vm.closeTheModel}})]},proxy:true}])})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }